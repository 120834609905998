import validate from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.7.0_db0@0.2.4_eslint@9.12.0_jiti@2.4.2__magi_zcag65yvourqktcmc4ghu6c454/node_modules/nuxt/dist/pages/runtime/validate.js";
import reset_45breadcrumbs_45global from "/app/packages/kiwi-user/middleware/resetBreadcrumbs.global.ts";
import rollbar_45test_45global from "/app/packages/kiwi-user/middleware/rollbar-test.global.ts";
import set_45user_45agent_45global from "/app/packages/kiwi-user/middleware/setUserAgent.global.ts";
export const globalMiddleware = [
  validate,
  reset_45breadcrumbs_45global,
  rollbar_45test_45global,
  set_45user_45agent_45global
]
export const namedMiddleware = {
  "pages-public-article-dietqa-permalink": () => import("/app/packages/kiwi-user/middleware/pages-public-article-dietqa-permalink.ts"),
  "pages-public-article-dietqa": () => import("/app/packages/kiwi-user/middleware/pages-public-article-dietqa.ts"),
  "pages-public-article-news-permalink": () => import("/app/packages/kiwi-user/middleware/pages-public-article-news-permalink.ts"),
  "pages-public-article-news": () => import("/app/packages/kiwi-user/middleware/pages-public-article-news.ts"),
  "sidebase-auth": () => import("/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.10.0_magicast@0.3.5_next-auth@4.21.1_next@13.5.7_@babel+core@7.26.0_rea_pfymfychjhe5wvruvv6rdwgcli/node_modules/@sidebase/nuxt-auth/dist/runtime/middleware/sidebase-auth.js")
}