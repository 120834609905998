import payload_plugin_tOQkHXzYkO from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.6.2_vue@3.5.13_typescript@5.6.2__/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_p4kDAtQrWW from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.7.0_db0@0.2.4_eslint@9.12.0_jiti@2.4.2__magi_zcag65yvourqktcmc4ghu6c454/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_ACAhKd3mkg from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.7.0_db0@0.2.4_eslint@9.12.0_jiti@2.4.2__magi_zcag65yvourqktcmc4ghu6c454/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_eHvdDymzWa from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.7.0_db0@0.2.4_eslint@9.12.0_jiti@2.4.2__magi_zcag65yvourqktcmc4ghu6c454/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_l2wRuSGzWS from "/app/node_modules/.pnpm/nuxt-site-config@3.1.4_magicast@0.3.5_vue@3.5.13_typescript@5.6.2_/node_modules/nuxt-site-config/dist/runtime/app/plugins/0.siteConfig.js";
import payload_client_HfxFSV6o8L from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.7.0_db0@0.2.4_eslint@9.12.0_jiti@2.4.2__magi_zcag65yvourqktcmc4ghu6c454/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_G7YhVogvpU from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.7.0_db0@0.2.4_eslint@9.12.0_jiti@2.4.2__magi_zcag65yvourqktcmc4ghu6c454/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_HcS9Nbjt8O from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.7.0_db0@0.2.4_eslint@9.12.0_jiti@2.4.2__magi_zcag65yvourqktcmc4ghu6c454/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_rUsfreXD8l from "/app/node_modules/.pnpm/@pinia+nuxt@0.10.1_magicast@0.3.5_pinia@3.0.1_typescript@5.6.2_vue@3.5.13_typescript@5.6.2__/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/packages/kiwi-user/.nuxt/components.plugin.mjs";
import prefetch_client_8Y78hTkdwR from "/app/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@22.7.0_db0@0.2.4_eslint@9.12.0_jiti@2.4.2__magi_zcag65yvourqktcmc4ghu6c454/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_BCZRQk2x9O from "/app/node_modules/.pnpm/@sidebase+nuxt-auth@0.10.0_magicast@0.3.5_next-auth@4.21.1_next@13.5.7_@babel+core@7.26.0_rea_pfymfychjhe5wvruvv6rdwgcli/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import siteConfig_yEUSYyRdqt from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.6_magicast@0.3.5_rollup@4.34.8_vite@5.4.8_@types+node@22.7.0_sa_fm42vufuvwjhlgu5vsqof3s4ka/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_q01TcM2PoW from "/app/node_modules/.pnpm/nuxt-seo-experiments@4.0.0-rc.6_magicast@0.3.5_rollup@4.34.8_vite@5.4.8_@types+node@22.7.0_sa_fm42vufuvwjhlgu5vsqof3s4ka/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import plugin_client_NzIt6DVZsH from "/app/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.5_rollup@4.34.8/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import rollbar_XnPIYyOzed from "/app/packages/kiwi-user/plugins/rollbar.ts";
export default [
  payload_plugin_tOQkHXzYkO,
  revive_payload_client_p4kDAtQrWW,
  unhead_ACAhKd3mkg,
  router_eHvdDymzWa,
  _0_siteConfig_l2wRuSGzWS,
  payload_client_HfxFSV6o8L,
  navigation_repaint_client_G7YhVogvpU,
  chunk_reload_client_HcS9Nbjt8O,
  plugin_vue3_rUsfreXD8l,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8Y78hTkdwR,
  plugin_BCZRQk2x9O,
  siteConfig_yEUSYyRdqt,
  inferSeoMetaPlugin_q01TcM2PoW,
  plugin_client_NzIt6DVZsH,
  rollbar_XnPIYyOzed
]