// NOTE: Rollbarの通知テスト用
export default defineNuxtRouteMiddleware(async (to) => {
  const config = useRuntimeConfig();
  const { APP_ENV } = config.public;

  if (APP_ENV !== 'production' && to.query['rollbar-test-app'] !== undefined) {
    throw createError({
      message: 'Rollbar通知テスト（app:error）',
      cause: {
        foo: 'bar',
      },
    });
  }
});
