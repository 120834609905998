import Rollbar from 'rollbar';
import { createRollbarConfig } from '@shared/utils/rollbar/createRollbarConfig';
import { H3Error } from 'h3';

export default defineNuxtPlugin((nuxtApp) => {
  const {
    public: { rollbarClientAccessToken, commitHash, APP_ENV },
  } = useRuntimeConfig();

  const rollbar = new Rollbar(
    createRollbarConfig({
      accessToken: rollbarClientAccessToken,
      commitHash: commitHash,
      environment: APP_ENV,
      scrubFields: ['name', 'email', 'password'],
      // NOTE: CDN経由の外部JSのエラーを受け取らないために設定している
      hostSafeList: [
        'dietplus.jp',
        'b.dietplus.jp',
        // NOTE: ローカルで動かしたくなったとき用に指定しておく
        'kiwi-user.lvh.me',
      ],
    }),
  );

  function errorHandler(error: unknown) {
    const statusCode =
      isNuxtError(error) || error instanceof H3Error ? error.statusCode : 500;
    if (statusCode >= 400 && statusCode <= 499) {
      return;
    }

    // NOTE: エラーの型の特定は難しいので as any する
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rollbar.error(error as any);
  }

  nuxtApp.hook('vue:error', errorHandler);
  nuxtApp.hook('app:error', errorHandler);

  return {
    provide: {
      rollbar,
    },
  };
});
